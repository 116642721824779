
import { defineComponent, computed } from 'vue'
import { useUserStore } from '@/pinia/user/user.pinia'

export default defineComponent({
	name: 'ErrorPage',
	setup() {
		const userStore = useUserStore()
		const userData = computed(() => userStore.userData)

		return { userData }
	}
})
